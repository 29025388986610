.sideNavContainer {
    background-color: #19a2b8;
    height: 100vh;
    z-index: 100;
    .nav-link {
        color: #ffffff;
    }
}

@media screen and (max-width: 992px) {
    .sideNavContainer {
        position: absolute;
        z-index: 2;
    }
}





