.emailButtons button {
    font-size: 0.5rem;
    font-weight: bold;
}

.emailBody {
    line-height: 20px;
}

@media screen and (max-width: 576px) {
    .emailDisplayContainer {
        position: absolute;
        background-color: white;
        width: 100vw;
        height: 100vh;
        z-index: 1;
    }
}